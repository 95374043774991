import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { Subject, take, takeUntil } from 'rxjs';

import { FocusableComponent } from '../focusable';

@Component({
  selector: 'vi-ui-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkComponent extends FocusableComponent implements OnDestroy {
  @Input() text: string;
  @Input() href: string = 'javascript:void(0)';
  @Input() role: string = 'link';
  @Input() target: string = '_blank';
  @Input() size: '12-16' | '13-18' | '14-20' = '14-20';
  @Input() inline? = false;
  @Input() highContrast? = false;
  @Output() linkClicked = new EventEmitter<string>();

  private destroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {
    super();
    this.accessibilityModeSubject.pipe(takeUntil(this.destroy$), take(1)).subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
