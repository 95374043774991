import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable, of } from 'rxjs';

import { EnvironmentNames } from '@common/modules/shared/environments';

import { ConfigService } from '../../core/services/config/config.service';
import { IConfiguration } from '../../core/services/interfaces';
import { UrlService } from '../../core/services/url/url.service';
import { AccountApi } from '../classes/account-api.class';
import { ArmAccountApi } from '../classes/arm-account-api.class';
import { UserApi } from '../classes/user-api.class';
import { IHttpWrapper, RegionType } from '../interfaces';
import { NotificationsApi } from '../classes/notifications-api.class';
import { SupportedLanguagesApi } from '../classes/supported-languages-api.class';
import { EdgeExtensionsApi } from '../classes/edge-extensions-api.class';
import { AmsMigrationApi } from '../classes/ams-migration-api.class';

@Injectable()
export class ApiService {
  // API CLASSES
  public User: UserApi;
  public Account: AccountApi;
  public ArmAccount: ArmAccountApi;
  public EdgeExtensions: EdgeExtensionsApi;
  public Notifications: NotificationsApi;
  public SupportedLanguages: SupportedLanguagesApi;
  public AmsMigrationApi: AmsMigrationApi;

  private config: IConfiguration = {};
  private apiCache: object;
  private apiLocation: string = RegionType.TRIAL;
  private defaultLocation: string = RegionType.TRIAL;
  private configLoaded: Observable<boolean> = of(false);
  private authRoutePath = ''; // '/auth';
  private apiMediaLocation = '';
  private _edgeExtensionApiUrl = null;

  constructor(private http: HttpClient, private urlUtility: UrlService, private configService: ConfigService, private injector: Injector) {
    this.init();
  }

  public set edgeExtensionApiUrl(value: string) {
    this._edgeExtensionApiUrl = value;
  }

  public get edgeExtensionApiUrl() {
    return this._edgeExtensionApiUrl;
  }

  public get baseApi() {
    return this.config.api.apimBase;
  }

  public getApiOperationsBase(removeApiLocation?: boolean, location?: string, forceCloudApi = false): string {
    const url = this.apiOperationsBase;

    if (location) {
      return this.apiOperationsBaseWithLocation(location, forceCloudApi);
    }

    if (this.config.local || !!removeApiLocation || this.configService.isEnvType(EnvironmentNames.LocalhostBE)) {
      return this.apiOperationsBase;
    }

    return this.apiOperationsBaseWithLocation(this.apiLocation, forceCloudApi);
  }

  public getAuthApiBase(isAuthPath: boolean = false): string {
    return `${this.config?.api?.authorizationApiLocation}${isAuthPath ? this.authRoutePath : ''}`;
  }

  public getInternalApiBase(location?: string, defaultLocation = false, forceCloudApi = false): string {
    if (this.config.local || this.configService.isEnvType(EnvironmentNames.LocalhostBE)) {
      return this.apiInternalsBase;
    }

    if (location) {
      return this.apiInternalsBaseWithLocation(location, forceCloudApi);
    }

    if (defaultLocation) {
      return this.apiInternalsBaseWithLocation(this.defaultLocation, forceCloudApi);
    }

    return this.apiInternalsBaseWithLocation(this.apiLocation, forceCloudApi);
  }

  public getApiLocation(): string {
    return this.apiLocation;
  }

  public getApiMediaLocation(): string {
    return this.apiMediaLocation;
  }

  public getProxyApiLocation(): string {
    return this.config.proxyUrlBase;
  }

  public isTrialApi(): boolean {
    return this.apiLocation === RegionType.TRIAL;
  }

  // Get location and set the url
  public setApiBaseLocation(location: string, accountType: string) {
    if (accountType === 'Paid' || accountType === 'paid') {
      this.apiLocation = location;
    } else {
      this.apiLocation = RegionType.TRIAL;
    }
  }

  public setApiMediaLocation(location: string) {
    this.apiMediaLocation = location;
  }

  public isConfigLoaded(): Observable<boolean> {
    return this.configLoaded;
  }

  private init() {
    this.User = new UserApi(this.ApiWrapper);
    this.Account = new AccountApi(this.ApiWrapper);
    this.ArmAccount = new ArmAccountApi(this.ApiWrapper);
    this.Notifications = new NotificationsApi(this.ApiWrapper);
    this.SupportedLanguages = new SupportedLanguagesApi(this.ApiWrapper);
    this.EdgeExtensions = new EdgeExtensionsApi(this.ApiWrapper);
    this.AmsMigrationApi = new AmsMigrationApi(this.ApiWrapper);
    this.config = this.configService.Config;

    this.apiLocation = this.urlUtility.getQueryParam('location') || RegionType.TRIAL;
    this.defaultLocation = this.configService.Config?.api?.defaultRegion || RegionType.TRIAL;
  }

  private get ApiWrapper(): IHttpWrapper {
    return {
      GetApiOperationsBase: (removeApiLocation?: boolean, location?: string, forceCloudApi?: boolean) =>
        this.getApiOperationsBase(removeApiLocation, location, forceCloudApi),
      GetInternalApiBase: (location?: string, defaultLocation = false, forceCloudApi?: boolean) =>
        this.getInternalApiBase(location, defaultLocation, forceCloudApi),
      GetAuthApiBase: (isAuthPath: boolean = false) => this.getAuthApiBase(isAuthPath),
      GetApiLocation: () => this.getApiLocation(),
      GetApiMediaLocation: () => this.getApiMediaLocation(),
      GerProxyApiLocation: () => this.getProxyApiLocation(),
      Cache: this.apiCache,
      UrlUtility: this.urlUtility,
      HttpClient: this.http,
      Injector: this.injector
    };
  }

  private get apiInternalsBase(): string {
    return this._edgeExtensionApiUrl ? this._edgeExtensionApiUrl : this.config?.api?.internalApiLocation;
  }

  private get apiOperationsBase(): string {
    return this._edgeExtensionApiUrl ?? this.config?.api?.operationsApiLocation;
  }

  private apiInternalsBaseWithLocation(location?: string, forceCloudApi = false): string {
    return !!this._edgeExtensionApiUrl && !forceCloudApi
      ? this._edgeExtensionApiUrl
      : `${this.config?.api?.internalApiLocation}/${location ?? this.apiLocation}`;
  }

  private apiOperationsBaseWithLocation(location?: string, forceCloudApi = false): string {
    return !!this._edgeExtensionApiUrl && !forceCloudApi
      ? this._edgeExtensionApiUrl
      : `${this.config?.api?.operationsApiLocation}/${location ?? this.apiLocation}`;
  }
}
