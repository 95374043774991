import { Routes } from '@angular/router';

import { AccountPermission } from '@common/modules/shared/interfaces';

import { RouteResolver } from '../../core/resolvers/route.resolver';
import { UserMetadataResolver } from '../../core/resolvers/user-metadata.resolver';
import { VIRoutingMap } from './routes';
import { MediaMetadataResolver } from '../../core/resolvers/media-metadata.resolver';
import { PageNotFoundComponent } from '../../components/page-not-found/page-not-found.component';
import { LoginErrorComponent } from '../../components/login-error/login-error.component';
import { UserAlreadyExistsComponent } from '../../components/user-already-exists/user-already-exists.component';
import { AuthGuard } from '../../core/guards/auth-guard.service';
import { AuthenticatedResolver } from '../../core/resolvers/authenticated.resolver';
import { PermissionGuard } from '../../core/guards/permission.guard.service';
import { EdgeExtensionsMediaMetadataResolver } from '../../core/resolvers/edge-extensions-media-metadata.resolver';
import { RouteSelectAccountResolver } from '../../core/resolvers/route-select-account.resolver';
import { AmsMigrationReportGuard } from '../../core/guards/ams-migration-page-guard.service';
import { NetworkAccessGuard } from '../../core/guards/network-access.guard.service';

export const appRoutes: Routes = [
  {
    path: VIRoutingMap.customization.path,
    loadChildren: () => import('../../customization/customization.module').then(m => m.CustomizationModule),
    canActivate: [AuthGuard, NetworkAccessGuard],
    resolve: { resolver: UserMetadataResolver, routeResolver: RouteSelectAccountResolver },
    runGuardsAndResolvers: 'always',
    data: VIRoutingMap.customization
  },
  {
    path: `${VIRoutingMap.customization.path}/${VIRoutingMap.customizationPeople.path}`,
    loadChildren: () => import('../../customization/customization.module').then(m => m.CustomizationModule),
    canActivate: [AuthGuard, NetworkAccessGuard],
    data: VIRoutingMap.customizationPeople
  },
  {
    path: `${VIRoutingMap.customization.path}/${VIRoutingMap.customizationLanguage.path}`,
    loadChildren: () => import('../../customization/customization.module').then(m => m.CustomizationModule),
    canActivate: [AuthGuard, NetworkAccessGuard],
    data: VIRoutingMap.customizationLanguage
  },
  {
    path: `${VIRoutingMap.customization.path}/${VIRoutingMap.customizationBrands.path}`,
    loadChildren: () => import('../../customization/customization.module').then(m => m.CustomizationModule),
    canActivate: [AuthGuard, NetworkAccessGuard],
    data: VIRoutingMap.customizationBrands
  },
  {
    path: `${VIRoutingMap.customization.path}/${VIRoutingMap.customizationSpeech.path}`,
    loadChildren: () => import('../../customization/customization.module').then(m => m.CustomizationModule),
    canActivate: [AuthGuard, NetworkAccessGuard],
    data: VIRoutingMap.customizationSpeech
  },
  {
    path: VIRoutingMap.editorNewProject.path,
    loadChildren: () => import('../../editor-wrapper/editor-wrapper.module').then(m => m.EditorWrapperModule),
    canActivate: [AuthGuard, PermissionGuard(AccountPermission.CONTRIBUTOR), NetworkAccessGuard],
    resolve: { resolver: UserMetadataResolver, routeResolver: RouteSelectAccountResolver },
    runGuardsAndResolvers: 'always',
    data: VIRoutingMap.editorNewProject
  },
  {
    path: VIRoutingMap.editorProject.path,
    loadChildren: () => import('../../editor-wrapper/editor-wrapper.module').then(m => m.EditorWrapperModule),
    canActivate: [AuthGuard, PermissionGuard(AccountPermission.CONTRIBUTOR), NetworkAccessGuard],
    resolve: { resolver: UserMetadataResolver, routeResolver: RouteSelectAccountResolver },
    runGuardsAndResolvers: 'always',
    data: VIRoutingMap.editorProject
  },
  {
    path: VIRoutingMap.editorVideo.path,
    loadChildren: () => import('../../editor-wrapper/editor-wrapper.module').then(m => m.EditorWrapperModule),
    canActivate: [AuthGuard, PermissionGuard(AccountPermission.CONTRIBUTOR), NetworkAccessGuard],
    resolve: { resolver: UserMetadataResolver, routeResolver: RouteSelectAccountResolver },
    data: VIRoutingMap.editorVideo
  },
  {
    path: VIRoutingMap.mediaVideo.path,
    loadChildren: () => import('../../media/media.module').then(m => m.MediaModule),
    canActivate: [NetworkAccessGuard],
    resolve: { resolver: MediaMetadataResolver },
    runGuardsAndResolvers: 'always',
    data: VIRoutingMap.mediaVideo
  },
  {
    path: VIRoutingMap.mediaProject.path,
    loadChildren: () => import('../../media/media.module').then(m => m.MediaModule),
    canActivate: [AuthGuard, NetworkAccessGuard],
    resolve: { resolver: MediaMetadataResolver },
    runGuardsAndResolvers: 'always',
    data: VIRoutingMap.mediaProject
  },
  {
    path: VIRoutingMap.edgeExtensionVideo.path,
    loadChildren: () => import('../../media/media.module').then(m => m.MediaModule),
    canActivate: [AuthGuard, NetworkAccessGuard],
    resolve: { resolver: EdgeExtensionsMediaMetadataResolver },
    runGuardsAndResolvers: 'always',
    data: VIRoutingMap.mediaProject
  },
  {
    path: VIRoutingMap.settings.path,
    loadChildren: () => import('../../settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard, PermissionGuard(AccountPermission.CONTRIBUTOR), NetworkAccessGuard],
    resolve: { resolver: UserMetadataResolver, routeResolver: RouteSelectAccountResolver },
    runGuardsAndResolvers: 'always',
    data: VIRoutingMap.settings
  },
  {
    path: VIRoutingMap.login.path,
    loadChildren: () => import('../../login/login.module').then(m => m.LoginModule),
    data: VIRoutingMap.login
  },
  {
    path: VIRoutingMap.logout.path,
    loadChildren: () => import('../../logout/logout.module').then(m => m.LogoutModule),
    data: VIRoutingMap.logout
  },
  { path: VIRoutingMap.pageNotFoundError.path, component: PageNotFoundComponent, data: VIRoutingMap.pageNotFoundError },
  { path: VIRoutingMap.loginError.path, component: LoginErrorComponent, data: VIRoutingMap.loginError, resolve: { resolver: AuthenticatedResolver } },
  { path: VIRoutingMap.userAlreadyExistsError.path, component: UserAlreadyExistsComponent, data: VIRoutingMap.userAlreadyExistsError },
  {
    path: VIRoutingMap.mediaGallery.path,
    loadChildren: () => import('../../gallery/gallery.module').then(m => m.GalleryModule),
    canActivate: [AuthGuard],
    resolve: {
      userMetadataResolver: UserMetadataResolver,
      routeResolver: RouteResolver
    },
    runGuardsAndResolvers: 'always',
    data: VIRoutingMap.mediaGallery
  },
  {
    path: VIRoutingMap.amsMigrationReport.path,
    loadChildren: () => import('../../ams-migration-report/ams-migration-report.module').then(m => m.AmsMigrationReportModule),
    canActivate: [AuthGuard, AmsMigrationReportGuard, NetworkAccessGuard],
    resolve: {
      userMetadataResolver: UserMetadataResolver,
      routeResolver: RouteSelectAccountResolver
    },
    runGuardsAndResolvers: 'always',
    data: { ...VIRoutingMap.amsMigrationReport, showHeaderButtons: false }
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: VIRoutingMap.mediaGallery.path
  },
  {
    path: '**',
    redirectTo: VIRoutingMap.pageNotFoundError.path
  }
];
