interface ICustomEventsTrackDataMap {
  [name: string]: string;
}

export interface ICustomEventsTrackData extends ICustomEventsTrackDataMap {
  accountId?: string;
  sessionId?: string;
  locale?: string;
  action?: string;
  category?: string;
  browserLanguage?: string;
  appVersion?: string;
  host?: string;
  monitoringEnvironment?: string;
  embed?: string;
  location?: string;
  refferer?: string;
}

export interface IEnvironmentTypeConfiguration {
  logger?: boolean;
  mapArmLocation?: boolean;
}

export interface IConfiguration extends IEnvironmentTypeConfiguration, INodeConfig {
  domain?: string;
  debug?: boolean;
  version?: string;
  local?: boolean;
  production?: boolean;
  proxyUrlBase?: string;
}

export interface IFeatureSwitches {
  [index: string]: boolean;
}

export enum FeatureSwitch {
  SettingTabsView = 'SettingTabsView',
  ARM = 'ARM',
  FaceGate = 'FaceGate',
  AdvancedEditTranscript = 'AdvancedEditTranscript',
  SurveyPopup = 'SurveyPopup',
  SlateDetection = 'SlateDetection',
  RestrictedViewer = 'RestrictedViewer',
  EditSpeakers = 'EditSpeakers',
  DisableSummarizedInsights = 'DisableSummarizedInsights',
  Logos = 'Logos',
  Speech = 'Speech',
  InternalFaceGatePopup = 'InternalFaceGatePopup',
  SwitchDirectory = 'SwitchDirectory',
  NotificationCenter = 'NotificationCenter',
  AutoDetectLanguage = 'AutoDetectLanguage',
  SupportedLanguages = 'SupportedLanguages',
  NewUploadExperience = 'NewUploadExperience',
  Edge = 'Edge',
  EdgeTokens = 'EdgeTokens',
  EdgeBasicPreset = 'EdgeBasicPreset',
  EdgeMultiLid = 'EdgeMultiLid',
  ImageUploadSupport = 'ImageUploadSupport',
  ISA = 'ISA',
  FacesQuality = 'FacesQuality',
  ShowAllFaces = 'ShowAllFaces',
  NewFilterPerson = 'NewFilterPerson',
  MergePeople = 'MergePeople',
  GlobalGrouping = 'GlobalGrouping',
  BasicVideoPreset = 'BasicVideoPreset',
  Amsless = 'Amsless',
  MigrationReport = 'MigrationReport',
  ExcludeAIs = 'ExcludeAIs',
  ProjectsForAMSless = 'ProjectsForAMSless',
  UseCacheApi = 'UseCacheApi',
  TextualSummary = 'TextualSummary',
  TextualSummaryForEdge = 'TextualSummaryForEdge',
  IncludeCelebs = 'IncludeCelebs',
  HideClassicAccounts = 'HideClassicAccounts',
  RemoveAmsMigrationStatus = 'RemoveAmsMigrationStatus',
  TextualSummaryForEdgeKeyframes = 'TextualSummaryForEdgeKeyframes'
}

export interface IUIAccount {
  name: string;
  id: string;
  location: string;
  selected?: boolean;
  accountType?: string;
  resourceType?: Microsoft.VideoIndexer.Contracts.AccountResourceType;
}

export interface IUIDirectory {
  id: string;
  tenantId: string;
  countryCode: string;
  displayName: string;
  domains: string[];
  tenantCategory: string;
  defaultDomain: string;
  tenantType: string;
}

export interface IUserAccounts {
  accounts: IUIAccount[];
  selectedAccount?: IUIAccount;
  selectedAccountId: string;
}

export interface IUserDetails {
  imageUrl: string;
  name: string;
  email: string;
}

export interface IUserSettings {
  getNewsUpdates: boolean;
  autoCompleteSearch: boolean;
  showSamplesTab: boolean;
  showGalleryByDefault: boolean;
  locale?: string;
  userType?: Microsoft.VideoIndexer.Contracts.UserType;
}

export interface ISideBar {
  userAccounts: IUserAccounts;
  userSettings: IUserSettings;
}

export interface IInvite {
  id: string;
  showForm?: boolean;
}

export enum NavigationState {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  TOGGLE = 'TOGGLE',
  ERROR = 'ERROR'
}

export enum AccountEvent {
  LEAVE = 'LEAVE',
  SELECTED = 'SELECTED'
}

export enum VIThemes {
  LEGACY = 'legacy',
  DARK = 'dark',
  DARKLEGACY = 'darkLegacy',
  DEFAULT = 'default'
}

export enum SideMenuOption {
  Media = 'Media',
  Customizations = 'Customizations',
  Settings = 'Settings'
}

export enum SideSubMenuType {
  Customizations = 'Customizations',
  Extensions = 'Extensions'
}

export enum CustomizationPage {
  People = 'People',
  Language = 'Language',
  Speech = 'Speech',
  Brands = 'Brands',
  Logos = 'Logos',
  LanguageId = 'LanguageId'
}
